<template>
  <g>
    <svg:style>
      .cls-4, .cls-5, .cls-6, .cls-7 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4, .cls-5, .cls-6, .cls-7 {
        stroke-linecap: square;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#inox-gradient-2);
      }

      .cls-7 {
        fill: url(#inox-gradient-3);
      }
    </svg:style>
    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 509.13"
                    :y1="doorTopHeight1 + 7354.61"
                    :x2="doorLeftWidth1 + 578.74"
                    :y2="doorTopHeight1 + 7354.61"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient"
                    :x1="doorLeftWidth1 + 543.94"
                    :y1="doorTopHeight1 + 7407.23"
                    :x2="doorLeftWidth1 + 543.94"
                    :y2="doorTopHeight1 + 7302.11"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 543.94"
                    :y1="doorTopHeight1 + 7496.18"
                    :x2="doorLeftWidth1 + 543.94"
                    :y2="doorTopHeight1 + 7468.15"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-3"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 543.94"
                    :y1="doorTopHeight1 + 7454.28"
                    :x2="doorLeftWidth1 + 543.94"
                    :y2="doorTopHeight1 + 7426.24"
                    xlink:href="#handle-gradient"/>
    <g id="С4">
      <path id="inox"
            v-if="showInox"
            class="cls-4"
            :d="`
            M${doorLeftWidth1 + 509.13},${doorTopHeight1 + 7310.42}
            s10,0.6,20.1-7.65
            c7.71-6.09,14.93-5.7,15-5.7
            s6.31-.62,15.53,6
            c10.73,7.87,19,7.35,19,7.35
            v101.74
            H${doorLeftWidth1 + 509.13}
            V${doorTopHeight1 + 7310.42}
            Z
            `"
            transform="translate(-474.03 -7249.12)"/>
      <path id="glass"
            class="cls-5"
            :d="`
            M${doorLeftWidth1 + 514.37},${doorTopHeight1 + 7314.52}
            a33.06,33.06,0,0,0,17.07-7.56
            c6.8-5.07,12.23-4.83,12.73-4.85
            s5.3-.24,13.19,5.1
            c9.11,6.69,16.14,7.95,16.14,7.95
            v92.06
            H${doorLeftWidth1 + 514.37}
            v-92.7
            Z
            `"
            transform="translate(-474.03 -7249.12)"/>
      <path id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-6"
            :d="`
            M${doorLeftWidth1 + 509},${doorTopHeight1 + 7468.15}
            v28
            h69.78
            v-28
            H${doorLeftWidth1 + 509}
            Z
            m65,23.07
            H${doorLeftWidth1 + 513.86}
            V${doorTopHeight1 + 7473}
            H${doorLeftWidth1 + 574}
            v18.25
            Z
            `"
            transform="translate(-474.03 -7249.12)"/>
      <path id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="cls-7"
            :d="`
            M${doorLeftWidth1 + 509},${doorTopHeight1 + 7426.24}
            v28
            h69.78
            v-28
            H${doorLeftWidth1 + 509}
            Z
            m65,23.07
            H${doorLeftWidth1 + 513.86}
            v-18.25
            H${doorLeftWidth1 + 574}
            v18.25
            Z
            `"
            transform="translate(-474.03 -7249.12)"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  }
}
</script>
